<template>
  <v-row>
    <v-col cols="auto">
      <v-row>
        <v-col cols="auto" class="font-weight-black pr-0">
          Cycle Count Number:
        </v-col>
        <v-col>
          <div>
            {{ nextCycleCount.cycle_count_number }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto">
      <v-row>
        <v-col cols="auto" class="font-weight-black pr-0"> Location: </v-col>
        <v-col>
          <div>
            {{ nextCycleCount.location }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto">
      <v-row>
        <v-col cols="auto" class="font-weight-black pr-0"> SKU: </v-col>
        <v-col>
          <div>
            {{ nextCycleCount.sku }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto" v-if="!isBlintCountEnabled">
      <v-row>
        <v-col cols="auto" class="font-weight-black pr-0">
          Total Quantity:
        </v-col>
        <v-col>
          <div>
            {{ nextCycleCount.total_qty }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto">
      <v-row>
        <v-col cols="auto" class="font-weight-black pr-0">
          Count quantity:
        </v-col>
        <v-col>
          <div>
            {{ nextCycleCount.count_qty }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto">
      <v-row>
        <v-col cols="auto" class="font-weight-black pr-0"> Status: </v-col>
        <v-col>
          <div>
            {{ nextCycleCount.status }}
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NextCycleCountInfo",
  computed: {
    nextCycleCount: function () {
      return this.$store.getters.getRANDOMCYCLECOUNTNextCycleCountTask;
    },
    isBlintCountEnabled: function () {
      return this.$store.getters.getRANDOMCYCLECOUNTBlindCycleCountState;
    },
  },
};
</script>
