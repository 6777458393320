<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :isFilterRequiredForExport="true"
      :isWarehouseRequired="true"
      :skipAddButton="true"
      :overrideActionsWith="actions"
      v-on:makeRecount="makeRecount"
      v-on:addNewAdjust="addNewAdjust"
    >
      <template v-slot:topActions="{ permissions }">
        <CycleCountActions
          :page-loading="setPageLoading"
          :refresher="updateTableData"
          v-if="permissions('create')"
        />
      </template>
    </DataTable>
    <RecountCycleTaskModal
      ref="adjustmentModal"
      :page-loading="setPageLoading"
    />
    <AddAdjustment
      ref="addAdjustment"
      :page-loader="setPageLoading"
      :refresher="updateTableData"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/randomCycleCount.module";
import CycleCountActions from "@/own/components/warehouseManagement/randomCycleCount/CycleCountActions.vue";
import RecountCycleTaskModal from "@/own/components/warehouseManagement/randomCycleCount/RecountCycleTaskModal.vue";
import AddAdjustment from "@/own/components/warehouseManagement/inventoryBalance/AddAdjustment";

export default {
  name: "RandomCycleCount",
  components: {
    DataTable,
    CycleCountActions,
    RecountCycleTaskModal,
    AddAdjustment,
  },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getRANDOMCYCLECOUNTTableFilters",
      getTableState: "getRANDOMCYCLECOUNTTableState",
      getTableData: "getRANDOMCYCLECOUNTTableData",
      getTableHeaders: "getRANDOMCYCLECOUNTTableHeaders",
      getTableProperties: "getRANDOMCYCLECOUNTTableProperties",
      getTableExportUrl: "getRANDOMCYCLECOUNTExportUrl",
      getTablePermissions: "getRANDOMCYCLECOUNTTablePermissions",
      getUploadType: "getRANDOMCYCLECOUNTUploadType",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        warehouse_id: this.selectedWarehouse,
      });
    },
    setPageLoading(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    addNewAdjust(item) {
      const item_clone = {
        id: item.inventory_balance_id,
      };
      this.$store.commit(SET_ITEM_FOR_ACTION, item_clone);
      this.$refs.addAdjustment.toggleModal();
    },
    makeRecount(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.adjustmentModal.toggleModal();
    },
    updateTableData() {
      this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        ...this.$store.getters[this.store_names.getTableState],
        warehouse_id: this.selectedWarehouse,
      });
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    actions: function () {
      return [
        {
          name: "adjustment",
          title: "Adjustment",
          type: "normal",
          method: "addNewAdjust",
          isVisible: true,
        },
        {
          name: "recount",
          title: "Recount",
          type: "normal",
          method: "makeRecount",
          isVisible: true,
        },
      ];
    },
  },
  beforeMount() {
    if (this.selectedWarehouse) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.initTableContent();
    }
  },
  watch: {
    selectedWarehouse: function (value) {
      if (value) {
        this.setPageLoading(true);
        this.initTableContent();
      }
    },
  },
};
</script>
